#buttonMenu {
  transition-property: all;
  transition-property: transform;
  transition-duration: 300ms; 
}
#buttonMenu.menuOpen {
  transform: rotate(180deg);
 -webkit-transform: rotate(180deg);
 -ms-transform: rotate(180deg);

}
#blur{
-webkit-box-shadow: -7px 0px 18px -10px rgba(0,0,0,0.75);
-moz-box-shadow: -7px 10px 18px -10px rgba(0,0,0,0.75);
box-shadow: -7px 10px 18px -10px rgba(0,0,0,0.75);
}



