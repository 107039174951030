#navbar {
  max-height: 0;
  overflow: auto;
  transition: max-height 220ms ;
	/* Set our transitions up. */
}
#navbar.slideIn {
  max-height: 0;
}

#navbar.slideOut {
  max-height: 100px;
}

