html,
body {
  overflow: hidden;
  font-family: 'Roboto', sans-serif;
  font-size: 14px !important;
}

#calendar-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.fc-header-toolbar {
  padding-top: 1em;
}

.fc-month-view th {
  height: 30px !important;
}

.fc-unthemed td.fc-today {
  background: none !important;
}
.fc-agendaDay-view td,
.fc-agendaDay-view .fc-list-heading td {
  border-bottom-color: #f7f9fc !important;
}

.fc th {
  font-size: 18px !important;
  overflow: hidden;
  font-family: 'Roboto', sans-serif;
  color: rgb(0, 0, 0, 0.6);
  font-weight: normal;
  vertical-align: center !important;
  background-color: #f7f9fc;
}

.fc td {
  vertical-align: center !important;
}

.fc-state-default {
  border-collapse: separate !important;
  background-color: #ffffff;
  color: #00622b !important;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff) !important;
  border-style: none !important;
  box-shadow: none !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  text-shadow: none !important;
}

.fc-state-down,
.fc-state-active {
  background: #00622b !important;
  color: #ffffff !important;
  font-weight: 200 !important;
}

:focus {
  outline: none !important;
}

.fc-toolbar button {
  height: 40px !important;
  width: 80px;
  border-radius: 5px;
}

.fc-list-heading td {
  background: #f7f9fc !important;
}

.fc-list-heading {
  font-size: 16px !important;
  height: 50px;
}

.fc table {
  font-size: 16px !important;
  font-weight: 500 !important;
  color: rgb(0, 0, 0, 0.7) !important;
}

.fc-day-number {
  float: left !important;
  position: relative !important;
  top: 0.4em !important;
  left: 5px !important;
  display: flex;
  width: 18px;
  justify-content: center;
  padding-bottom: 10px !important;
  font-size: 13px !important;
}

.fc-prev-button,
.fc-next-button {
  color: #00622b !important;
  background-image: linear-gradient(to bottom, #f7f9fc, #f7f9fc) !important;
  border-style: none !important;
  box-shadow: none !important;
}

.fc-toolbar.fc-header-toolbar {
  margin-bottom: 15px !important;
}

.fc-header-toolbar {
  padding-top: 7px !important;
}

.fc-adicionarEvento-button {
  color: #00622b !important;
  margin-right: 10px !important;
}

.fc-today-button {
  margin-left: 5px !important;
  margin-right: 5px !important;
  border-radius: 5px !important;
}

.fc button {
  border-radius: none !important;
  height: none !important;
  margin-left: 0 !important;
  padding: 0 !important;
}

.fc-button {
  background: #f7f9fc;
}

.fc-state-hover {
  font-weight: 400 !important;
  color: #00622b !important;
  transition: none !important;
  background-color: #ffffff !important;
}

.fc-time-grid .fc-slats .fc-minor td {
  border-top-style: none !important;
}

.fc-divider {
  display: none !important;
}
.fc-center h2 {
  font-size: 20px !important;
  font-weight: 500 !important;
  color: rgb(0, 0, 0, 0.6) !important;
  position: relative !important;
  top: 5px !important;
}

.fc .fc-prev-button,
.fc-next-button {
  padding: 0px !important;
  background-image: -webkit-linear-gradient(top, #ffffff, #ffffff) !important;
  width: 38px !important;
  margin-right: 5px;
}

.fc-center {
  position: absolute !important;
}
.fc-event {
  border-radius: 0px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  border-width: 0 0 0 2px !important;
  background-color: transparent;
}
.fc-month-view .fc-event {
  height: 25px !important;
}
.fc-content {
  padding-left: 1px !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  display: flex;
}

.fc-month-view td:not(.fc-today),
.fc-month-view th:not(.fc-today) {
  border-width: 0.5px !important;
}

.fc-month-view .fc-event {
  height: 31px !important;
}

.fc-description {
  left: 1px !important;
}

.fc th,
.fc td {
  vertical-align: middle !important;
}

.MuiPaper-rounded-253 {
  border-radius: 0 !important;
}

.fc-state-default.fc-corner-right {
  border-radius: none !important;
  border-top-right-radius: 4 !important;
  border-bottom-right-radius: 4 !important;
}

.fc-agenda-view .fc-day-grid {
  display: none;
}

.fc-day-grid-event .fc-time {
  display: none;
}

.fc-time-grid-event .fc-time {
  display: none !important;
}

.fc-day-header {
  height: 30px;
}

.fc-event .fc-bg {
  background-color: #ffffff !important;
}

.fc-toolbar .fc-right {
  border: 1px solid #46a770 !important;
  border-radius: 5px;
}

.fc-month-button {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-right: 1px solid #46a770 !important;
}

.fc-agendaWeek-button {
  border-radius: 0px !important;
  border-right: 1px solid #46a770 !important;
}

.fc-agendaDay-button {
  border-radius: 0px !important;
  border-right: 1px solid #46a770 !important;
}

.fc-listMonth-button {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-width: 0 !important;
}

.fc-today-button {
  border: 1px solid #46a770 !important;
}

.fc-next-button {
  border: 1px solid #46a770 !important;
}

.fc-prev-button {
  border: 1px solid #46a770 !important;
}

.fc-view,
.fc-view > table {
  border: 1px solid rgb(221, 221, 221);
  border-radius: 5px;
}

.style-image-icon {
  margin-right: 5px;
  height: 17px;
  width: 15px;
  display: flex;
  position: absolute;
  margin-top: -18px;
  margin-left: -22px !important;
}

.material-icons {
  font-size: 16px;
  margin-left: 4px;
  margin-right: 3px;
}

.style-descricao {
  display: flex;
  flex-direction: row;
}

.fc-time-grid .fc-slats td {
  height: 2em;
  color: rgba(0, 0, 0, 0.5);
  border-top-color: rgb(112, 112, 112, 0.07) !important;
}

.style-p {
  margin: 0;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500 !important;
}

.style-time {
  display: flex;
  position: relative;
  float: left;
  margin-top: 2px;
}

.fc-today {
  background-color: #00622b !important;
  color: #ffffff !important;
}

.title-lista {
  margin: 0;
  width: 60%;
  display: flex;
  align-items: center;
  color: #46a770;
  margin-top: 3px;
}

.icon-color-visita {
  color: #46a770;
}

.icon-color-evento {
  color: rgb(255, 152, 33);
}

.title-lista-evento {
  margin: 0;
  width: 60%;
  display: flex;
  align-items: center;
  color: rgb(255, 152, 33);
  margin-top: 3px;
}

.img-lista {
  margin-right: 10px;
}

.fc-list-item {
  height: 50px;
  cursor: default;
}

.fc-list-item-title a {
  display: flex;
  align-items: center;
}

.fc table {
  font-weight: 400 !important;
}

.fc-list-item-time {
  font-size: 14px;
  display: table-cell;
}

.descrip {
  font-weight: 400 !important;
  color: rgb(0, 0, 0, 0.7) !important;
}

.today {
  border-radius: 50%;
  height: 25px;
  width: 24px;
  background-color: #00622b;
  margin-left: 4px;
  display: flex;
  align-items: flex-start;
  margin-top: 3px;
}

.fc td.fc-today {
  display: flex;
  height: 100%;
  border-style: unset !important;
  border-top-style: double !important;
}

.fc-time-grid-event.fc-short .fc-title {
  font-size: 14px !important;
}

.fc-list-table td {
  padding-top: 0px !important;
  padding-right: 0px !important;
  padding-bottom: 0px !important;
}

.fc-time-grid .fc-event,
.fc-time-grid .fc-bgevent {
  margin-right: 0px !important;
}

.fc-time-grid-event .fc-time,
.fc-time-grid-event .fc-title {
  padding: 0 !important;
}
