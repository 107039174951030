.run-animation-close {
  transition: opacity 0.3s linear;
  color: white;
  font-size: 14px;
  opacity: 0;
}

.run-animation-open {
  transition: opacity 0.5s linear;
  color: white;
  font-size: 14px;
  opacity: 1;
}
#run-animation-close-logo {
  transition: opacity 0.2s linear;
  transition-delay: 0.1 s;
  color: white;
  font-size: 14px;
  opacity: 1;
}

#run-animation-open-logo{
  color: white;
  font-size: 14px;
  opacity: 0;
}
.MuiListItem-selected-220{
  background-color: #00622B
}
#iconRight{
  transition-duration: 300ms;
  transition-property: transform;
  transform: rotate(90deg);
}
#iconDown{
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transition-duration: 300ms;
  transition-property: transform;
}

