
.card-icon {
  height: 20px;
  align-items: center;
  justify-content: center;
  display: flex;
  margin: 0 !important;
  color: #FFFFFF;
  margin-left: -5px !important;
}

.card-all {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  display: flex;
  align-items: flex-start;
}

.leaflet-popup-content-wrapper {
  background-color: #7C7D7D;
}

.leaflet-popup-tip {
  background-color: #7C7D7D;
}

.align-icon {
  display: flex;
}
