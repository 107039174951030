body, html {
  height: 100%;
  margin:0px;
  overflow: hidden;
  font-family: 'Roboto', sans-serif;
  font-weight:300 !important
}

#navbar {
  max-height: 0;
  overflow: auto;
  transition: max-height 220ms ;
	/* Set our transitions up. */
}
#navbar.slideIn {
  max-height: 0;
}

#navbar.slideOut {
  max-height: 100px;
}


.gm-style {
  color: #000000;
}

#buttonMenu {
  transition-property: all;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  transition-duration: 300ms; 
}
#buttonMenu.menuOpen {
  transform: rotate(180deg);
 -webkit-transform: rotate(180deg);
 -ms-transform: rotate(180deg);

}
#blur{
box-shadow: -7px 10px 18px -10px rgba(0,0,0,0.75);
}




.run-animation-close {
  transition: opacity 0.3s linear;
  color: white;
  font-size: 14px;
  opacity: 0;
}

.run-animation-open {
  transition: opacity 0.5s linear;
  color: white;
  font-size: 14px;
  opacity: 1;
}
#run-animation-close-logo {
  transition: opacity 0.2s linear;
  transition-delay: 0.1 s;
  color: white;
  font-size: 14px;
  opacity: 1;
}

#run-animation-open-logo{
  color: white;
  font-size: 14px;
  opacity: 0;
}
.MuiListItem-selected-220{
  background-color: #00622B
}
#iconRight{
  transition-duration: 300ms;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
#iconDown{
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transition-duration: 300ms;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
}


.col-grid-1 {
  width: calc(1 * (100% / 12)) !important;
}

.col-grid-2 {
  width: calc(2 * (100% / 12)) !important;
}

.col-grid-3 {
  width: calc(3 * (100% / 12)) !important;
}

.col-grid-4 {
  width: calc(4 * (100% / 12)) !important;
}

.col-grid-5 {
  width: calc(5 * (100% / 12)) !important;
}

.col-grid-6 {
  width: calc(6 * (100% / 12)) !important;
}

.col-grid-7 {
  width: calc(7 * (100% / 12)) !important;
}

.col-grid-8 {
  width: calc(8 * (100% / 12)) !important;
}

.col-grid-9 {
  width: calc(9 * (100% / 12)) !important;
}

.col-grid-10 {
  width: calc(10 * (100% / 12)) !important;
}

.col-grid-11 {
  width: calc(11 * (100% / 12)) !important;
}

.col-grid-12 {
  width: calc(12 * (100% / 12)) !important;
}

.grid-padding-left {
  padding-left: 15px !important;
  padding-right: 0 !important;
}

.grid-padding-right {
  padding-left: 0 !important;
  padding-right: 15px !important;
}

.ag-cell-label-container {
  height: 100%;
  font-size: 16px !important;
  font-weight: 400;
  cursor: pointer;
  color: #000000;
  opacity: 0.75;
}

.ag-header-cell-sorted-asc {
  opacity: 1 !important;
}

.ag-header-cell-sorted-desc {
  opacity: 1 !important;
}

.ag-cell {
  font-size: 14px !important;
  border: 0 !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  position: static !important;
  position: initial !important;
}

.ag-cell:focus {
  border: none !important;
  outline: none;
}

.ag-row-selected {
  border-bottom: 2px solid #00863b !important;
}

.ag-row-drag {
  margin-left: 15px;
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  height: auto !important;
}

.ag-row {
  width: 100% !important;
}

.ag-header-cell {
  position: static !important;
  position: initial !important;
}

.ag-layout-normal .ag-row {
  width: auto;
}

.ag-theme-material .ag-header {
  font-weight: 300 !important;
}

.ag-theme-material {
  font-weight: 300 !important;
}

.ag-theme-material .ag-icon-checkbox-checked:empty {
  color: #388e3c;
  background-image: none !important ;
}

.ag-paging-panel {
  height: 0px !important;
}

.ag-paging-page-summary-panel {
  display: none !important;
}

.ag-paging-row-summary-panel {
  display: none !important;
}

.ag-header-row {
  border-style: solid !important;
  border-color: rgba(0, 0, 0, 0) !important;
  box-sizing: border-box !important;
  border-width: 0px 0px 2px !important;
}

.ag-paging-panel {
  border-style: solid !important;
  border-color: rgba(0, 0, 0, 0.1) !important;
  box-sizing: border-box !important;
  border-width: 0px 0px 2px !important;
}

.ag-theme-material .ag-row-drag .ag-icon-row-drag {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAAAXNSR0IArs4c6QAAAL9JREFUOBHtkr0OAUEUhW/QSVRUOoWC1k+hkXgCTyDxAN6TGqVSoyZR8c3k3Mhe2+1WYpNvzsyZk7szN2P2s18z3GzFegMtuEAHdrCAIzwhZrDMGnn8DHNNZ9IR2hZjeTGT7Vhor/BBekbv4iQvZmTXJLFHXeqma6VTPPSPKdqHq9ZlGYuFtoRTXwaQrpfmaxjCTcQM9nezX9k1c9WyIL7nWtj0RTr2EnpuoBPhVlnG96pr7FF8bP8HWb3H9Vd4Az6mK2zQq6CVAAAAAElFTkSuQmCC);
  background-position-y: 0;
  height: 15px;
}

.ag-theme-material .ag-row {
  display: -webkit-flex;
  display: flex;
}

.ag-theme-material .ag-icon-checkbox-checked:empty {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAAAXNSR0IArs4c6QAAAYlJREFUOBGlkz1LA0EQht9dEyHgP9DG0n+Q5LQJWoiF2AmCnZVaKEqIWKTIh2BhKVilUkEQC3+AoF78KkTwo7NISJFGI4pwxhsnZ/YuF3NcTA6Omd135rmZ2T2BVHQSAlkIMYROHqJHEBIC6WiRIf2dMJwcKsruITWcGJAOtTuvQxCVYZoaz+ZGff7/IEIB39Uw1vM6iDYUKKCctizhGQEMI35V4kOa4NPeU3lNFVFFCS3sA3opjPh5CanIFA/4iN+gimsA0TGEHAToWom2JdzB+NCwqpe5kmlIecDVuLpxQCZySJy9wKjEuHe9AXKBr+oIkrevSEdmGbDLWo+t1x0HJMS+dcuT9+8I0SjrJ1zdKUJmDMnLN2Sic3znctyOqOe6TAOISxU4RFabwXL+E0ZwHH3lMcvPagsM2PGC1IgCGY1caO6L1/NY07et/UxkBZCb7pi/qxYgO2gJMHmg/pBahmvyNuLX2WJI05b3sv1Ib4alSP5fCj4x/jIzGESLfMxP/tFeEZzLjB/4OXK4NcGQUAAAAABJRU5ErkJggg==);
}

.ag-theme-material .ag-icon-grip:before {
  content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAAAXNSR0IArs4c6QAAAL9JREFUOBHtkr0OAUEUhW/QSVRUOoWC1k+hkXgCTyDxAN6TGqVSoyZR8c3k3Mhe2+1WYpNvzsyZk7szN2P2s18z3GzFegMtuEAHdrCAIzwhZrDMGnn8DHNNZ9IR2hZjeTGT7Vhor/BBekbv4iQvZmTXJLFHXeqma6VTPPSPKdqHq9ZlGYuFtoRTXwaQrpfmaxjCTcQM9nezX9k1c9WyIL7nWtj0RTr2EnpuoBPhVlnG96pr7FF8bP8HWb3H9Vd4Az6mK2zQq6CVAAAAAElFTkSuQmCC);
}

.ag-cell-wrapper {
  display: inline-block;
}

.ag-body-horizontal-scroll-viewport {
  overflow-y: hidden;
}

.ag-body-viewport.ag-layout-normal {
  overflow-y: overlay;
}

.ag-center-cols-viewport {
  width: calc(100% + 15px);
}

#button{
    padding: 8px 8px !important;
    min-width: 32px !important
}
 .MuiButton-root-344{
     padding: 10px 20px !important
 }
li{
    font-weight:300 !important  
}
p{
    font-weight:300 !important
}
button{
    font-weight:300 !important
}


#chipFiltro {
    font-weight: 500 !important;
    font-style: normal;
}
#openIcone{
  margin-top: 5px;
  margin-left: -4px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

  
  
  
  
.swal2-confirm {
    background-color: #00622B !important;
    border-color: #00622B !important;
    margin-right:120px
}
.swal2-cancel{
    background-color: #F33A30 !important;
    border-color: #F33A30!important;
    color:#FFFF !important;
    margin-right:10px
}
.swal2-Ok{
    background-color: #00622B !important;
    border-color: #00622B !important;
    margin-right:185px;
}
.swal2-error{
    background-color: #F33A30 !important;
    border-color: #F33A30!important;
    color:#FFFF !important;
    margin-right:185px
}
.swal-text{
    text-align: center !important
}

html,
body {
  overflow: hidden;
  font-family: 'Roboto', sans-serif;
  font-size: 14px !important;
}

#calendar-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.fc-header-toolbar {
  padding-top: 1em;
}

.fc-month-view th {
  height: 30px !important;
}

.fc-unthemed td.fc-today {
  background: none !important;
}
.fc-agendaDay-view td,
.fc-agendaDay-view .fc-list-heading td {
  border-bottom-color: #f7f9fc !important;
}

.fc th {
  font-size: 18px !important;
  overflow: hidden;
  font-family: 'Roboto', sans-serif;
  color: rgb(0, 0, 0, 0.6);
  font-weight: normal;
  vertical-align: center !important;
  background-color: #f7f9fc;
}

.fc td {
  vertical-align: center !important;
}

.fc-state-default {
  border-collapse: separate !important;
  background-color: #ffffff;
  color: #00622b !important;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff) !important;
  border-style: none !important;
  box-shadow: none !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  text-shadow: none !important;
}

.fc-state-down,
.fc-state-active {
  background: #00622b !important;
  color: #ffffff !important;
  font-weight: 200 !important;
}

:focus {
  outline: none !important;
}

.fc-toolbar button {
  height: 40px !important;
  width: 80px;
  border-radius: 5px;
}

.fc-list-heading td {
  background: #f7f9fc !important;
}

.fc-list-heading {
  font-size: 16px !important;
  height: 50px;
}

.fc table {
  font-size: 16px !important;
  font-weight: 500 !important;
  color: rgb(0, 0, 0, 0.7) !important;
}

.fc-day-number {
  float: left !important;
  position: relative !important;
  top: 0.4em !important;
  left: 5px !important;
  display: -webkit-flex;
  display: flex;
  width: 18px;
  -webkit-justify-content: center;
          justify-content: center;
  padding-bottom: 10px !important;
  font-size: 13px !important;
}

.fc-prev-button,
.fc-next-button {
  color: #00622b !important;
  background-image: linear-gradient(to bottom, #f7f9fc, #f7f9fc) !important;
  border-style: none !important;
  box-shadow: none !important;
}

.fc-toolbar.fc-header-toolbar {
  margin-bottom: 15px !important;
}

.fc-header-toolbar {
  padding-top: 7px !important;
}

.fc-adicionarEvento-button {
  color: #00622b !important;
  margin-right: 10px !important;
}

.fc-today-button {
  margin-left: 5px !important;
  margin-right: 5px !important;
  border-radius: 5px !important;
}

.fc button {
  border-radius: none !important;
  height: none !important;
  margin-left: 0 !important;
  padding: 0 !important;
}

.fc-button {
  background: #f7f9fc;
}

.fc-state-hover {
  font-weight: 400 !important;
  color: #00622b !important;
  transition: none !important;
  background-color: #ffffff !important;
}

.fc-time-grid .fc-slats .fc-minor td {
  border-top-style: none !important;
}

.fc-divider {
  display: none !important;
}
.fc-center h2 {
  font-size: 20px !important;
  font-weight: 500 !important;
  color: rgb(0, 0, 0, 0.6) !important;
  position: relative !important;
  top: 5px !important;
}

.fc .fc-prev-button,
.fc-next-button {
  padding: 0px !important;
  background-image: -webkit-linear-gradient(top, #ffffff, #ffffff) !important;
  width: 38px !important;
  margin-right: 5px;
}

.fc-center {
  position: absolute !important;
}
.fc-event {
  border-radius: 0px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  border-width: 0 0 0 2px !important;
  background-color: transparent;
}
.fc-month-view .fc-event {
  height: 25px !important;
}
.fc-content {
  padding-left: 1px !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  display: -webkit-flex;
  display: flex;
}

.fc-month-view td:not(.fc-today),
.fc-month-view th:not(.fc-today) {
  border-width: 0.5px !important;
}

.fc-month-view .fc-event {
  height: 31px !important;
}

.fc-description {
  left: 1px !important;
}

.fc th,
.fc td {
  vertical-align: middle !important;
}

.MuiPaper-rounded-253 {
  border-radius: 0 !important;
}

.fc-state-default.fc-corner-right {
  border-radius: none !important;
  border-top-right-radius: 4 !important;
  border-bottom-right-radius: 4 !important;
}

.fc-agenda-view .fc-day-grid {
  display: none;
}

.fc-day-grid-event .fc-time {
  display: none;
}

.fc-time-grid-event .fc-time {
  display: none !important;
}

.fc-day-header {
  height: 30px;
}

.fc-event .fc-bg {
  background-color: #ffffff !important;
}

.fc-toolbar .fc-right {
  border: 1px solid #46a770 !important;
  border-radius: 5px;
}

.fc-month-button {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-right: 1px solid #46a770 !important;
}

.fc-agendaWeek-button {
  border-radius: 0px !important;
  border-right: 1px solid #46a770 !important;
}

.fc-agendaDay-button {
  border-radius: 0px !important;
  border-right: 1px solid #46a770 !important;
}

.fc-listMonth-button {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-width: 0 !important;
}

.fc-today-button {
  border: 1px solid #46a770 !important;
}

.fc-next-button {
  border: 1px solid #46a770 !important;
}

.fc-prev-button {
  border: 1px solid #46a770 !important;
}

.fc-view,
.fc-view > table {
  border: 1px solid rgb(221, 221, 221);
  border-radius: 5px;
}

.style-image-icon {
  margin-right: 5px;
  height: 17px;
  width: 15px;
  display: -webkit-flex;
  display: flex;
  position: absolute;
  margin-top: -18px;
  margin-left: -22px !important;
}

.material-icons {
  font-size: 16px;
  margin-left: 4px;
  margin-right: 3px;
}

.style-descricao {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}

.fc-time-grid .fc-slats td {
  height: 2em;
  color: rgba(0, 0, 0, 0.5);
  border-top-color: rgb(112, 112, 112, 0.07) !important;
}

.style-p {
  margin: 0;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500 !important;
}

.style-time {
  display: -webkit-flex;
  display: flex;
  position: relative;
  float: left;
  margin-top: 2px;
}

.fc-today {
  background-color: #00622b !important;
  color: #ffffff !important;
}

.title-lista {
  margin: 0;
  width: 60%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  color: #46a770;
  margin-top: 3px;
}

.icon-color-visita {
  color: #46a770;
}

.icon-color-evento {
  color: rgb(255, 152, 33);
}

.title-lista-evento {
  margin: 0;
  width: 60%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  color: rgb(255, 152, 33);
  margin-top: 3px;
}

.img-lista {
  margin-right: 10px;
}

.fc-list-item {
  height: 50px;
  cursor: default;
}

.fc-list-item-title a {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.fc table {
  font-weight: 400 !important;
}

.fc-list-item-time {
  font-size: 14px;
  display: table-cell;
}

.descrip {
  font-weight: 400 !important;
  color: rgb(0, 0, 0, 0.7) !important;
}

.today {
  border-radius: 50%;
  height: 25px;
  width: 24px;
  background-color: #00622b;
  margin-left: 4px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  margin-top: 3px;
}

.fc td.fc-today {
  display: -webkit-flex;
  display: flex;
  height: 100%;
  border-style: unset !important;
  border-top-style: double !important;
}

.fc-time-grid-event.fc-short .fc-title {
  font-size: 14px !important;
}

.fc-list-table td {
  padding-top: 0px !important;
  padding-right: 0px !important;
  padding-bottom: 0px !important;
}

.fc-time-grid .fc-event,
.fc-time-grid .fc-bgevent {
  margin-right: 0px !important;
}

.fc-time-grid-event .fc-time,
.fc-time-grid-event .fc-title {
  padding: 0 !important;
}

.chartjs-render-monitor {
    display: -webkit-flex !important;
    display: flex !important;
    height: 100% !important;
    -webkit-flex: 1 1;
            flex: 1 1;
    -webkit-flex-direction: column;
            flex-direction: column
}
#autoComplete{
    transition: width 200ms;
}
.react-swipeable-view-container {
  position: relative;
  z-index: 1;
}


.card-icon {
  height: 20px;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  display: -webkit-flex;
  display: flex;
  margin: 0 !important;
  color: #FFFFFF;
  margin-left: -5px !important;
}

.card-all {
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}

.leaflet-popup-content-wrapper {
  background-color: #7C7D7D;
}

.leaflet-popup-tip {
  background-color: #7C7D7D;
}

.align-icon {
  display: -webkit-flex;
  display: flex;
}

.wrapper {
  -webkit-transform: translate(0%, 0%);
          transform: translate(0%, 0%);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition: width 275ms 100ms, height 375ms, -webkit-transform 375ms;
  transition: transform 375ms, width 275ms 100ms, height 375ms;
  transition: transform 375ms, width 275ms 100ms, height 375ms, -webkit-transform 375ms;
  font-size: 18px;
  margin-top: 30px;
  font-weight: 400;
  color: rgb(0, 0, 0, 0.6);
}
.wrapper_open {
  -webkit-transform: translate(-50%, -70%);
          transform: translate(-50%, -70%);
  transition: width 375ms, height 275ms 100ms, -webkit-transform 375ms;
  transition: transform 375ms, width 375ms, height 275ms 100ms;
  transition: transform 375ms, width 375ms, height 275ms 100ms, -webkit-transform 375ms;
}

.run-write-close {
  transition: opacity 0.5s linear;
  opacity: 0;
}

.run-write-open {
  transition: opacity 0.5s linear;
  opacity: 1;
}
.run-write-close-sucess {
  transition: opacity 0.5s linear;
  transition-delay: 0.5s;
  opacity: 1;
}

.run-write-open-sucess {
  opacity: 0;
}

