.swal2-confirm {
    background-color: #00622B !important;
    border-color: #00622B !important;
    margin-right:120px
}
.swal2-cancel{
    background-color: #F33A30 !important;
    border-color: #F33A30!important;
    color:#FFFF !important;
    margin-right:10px
}
.swal2-Ok{
    background-color: #00622B !important;
    border-color: #00622B !important;
    margin-right:185px;
}
.swal2-error{
    background-color: #F33A30 !important;
    border-color: #F33A30!important;
    color:#FFFF !important;
    margin-right:185px
}
.swal-text{
    text-align: center !important
}
