#button{
    padding: 8px 8px !important;
    min-width: 32px !important
}
 .MuiButton-root-344{
     padding: 10px 20px !important
 }
li{
    font-weight:300 !important  
}
p{
    font-weight:300 !important
}
button{
    font-weight:300 !important
}
