.wrapper {
  transform: translate(0%, 0%);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 375ms, width 275ms 100ms, height 375ms;
  font-size: 18px;
  margin-top: 30px;
  font-weight: 400;
  color: rgb(0, 0, 0, 0.6);
}
.wrapper_open {
  transform: translate(-50%, -70%);
  transition: transform 375ms, width 375ms, height 275ms 100ms;
}

.run-write-close {
  transition: opacity 0.5s linear;
  opacity: 0;
}

.run-write-open {
  transition: opacity 0.5s linear;
  opacity: 1;
}
.run-write-close-sucess {
  transition: opacity 0.5s linear;
  transition-delay: 0.5s;
  opacity: 1;
}

.run-write-open-sucess {
  opacity: 0;
}
